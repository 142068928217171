import { Layout } from './Layout';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'features/entry-form-v2/component/Input';
import { useSelector } from 'hooks/utils';
import { signIn } from '@aws-amplify/auth';
import { useNavigateToNextStep } from './useNavigateToNextStep';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'features/entry-form-v2/component/Button';

export function LoginPage() {
	const language = useSelector(state => state.ui.i18n.language);
	const translation = DICTIONARY[language];
	const [searchParams] = useSearchParams();

	const navigateToNextStep = useNavigateToNextStep();
	const navigate = useNavigate();

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const searchParamsSSO = searchParams.get('sso');

	useEffect(() => {
		if (searchParamsSSO) {
			navigate(ROUTE_MAP.auth.federatedLogin.createPath({ provider: searchParamsSSO }));
		}
	}, [searchParamsSSO, navigate]);

	const form = useForm({
		defaultValues: {
			username: '',
			password: ''
		},
		resolver: zodResolver(createSchema(translation))
	});

	const onSubmit = async (data: LoginFormData) => {
		try {
			setIsLoggingIn(true);
			const result = await signIn({
				username: data.username,
				password: data.password
			});

			const missingPhoneNumber =
				result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED' &&
				result.nextStep.missingAttributes?.includes('phone_number');

			navigateToNextStep({
				step: result.nextStep.signInStep,
				username: data.username,
				missingPhoneNumber
			});
		} catch (error) {
			if (isNotAuthorizedError(error)) {
				return form.setError('password', { message: translation.errors.notAuthorized });
			}

			form.setError('password', { message: translation.errors.unknown });
			console.error(error);
		} finally {
			setIsLoggingIn(false);
		}
	};

	return (
		<Layout title={translation.title}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
				<Input
					label={translation.usernameInput.label}
					placeholder={translation.usernameInput.placeholder}
					id={'username'}
					autoFocus
					autoComplete="username"
					error={form.formState.errors.username?.message}
					{...form.register('username')}
				/>

				<Input
					className="mt-4"
					label={translation.passwordInput.label}
					placeholder={translation.passwordInput.placeholder}
					type={'password'}
					id={'password'}
					autoComplete="current-password"
					error={form.formState.errors.password?.message}
					{...form.register('password')}
				/>

				<Button
					type="submit"
					title={translation.loginButtonText}
					loading={isLoggingIn}
					className="mt-6"
				/>

				<Link
					to={ROUTE_MAP.auth.forgotPassword.createPath({
						username: encodeURIComponent(form.watch('username'))
					})}
					className="text-sm font-semibold text-center mt-6 hover:underline"
				>
					{translation.forgotPasswordLinkText}
				</Link>
			</form>
		</Layout>
	);
}

const createSchema = (dictionary: Dictionary) =>
	z.object({
		username: z.string().min(1, dictionary.usernameInput.validation.username),
		password: z.string().min(1, dictionary.passwordInput.validation.password)
	});

type LoginFormData = z.infer<ReturnType<typeof createSchema>>;

const DICTIONARY = {
	EN: {
		title: 'Login',

		usernameInput: {
			label: 'Username or email',
			placeholder: 'Enter username or email',
			validation: {
				username: 'Username or email is required'
			}
		},

		passwordInput: {
			label: 'Password',
			placeholder: 'Enter password',
			validation: {
				password: 'Password is required'
			}
		},

		loginButtonText: 'Login',
		forgotPasswordLinkText: 'Forgot password?',

		validation: {
			username: 'Username or email is required',
			password: 'Password is required'
		},

		errors: {
			notAuthorized: 'Invalid username or password',
			unknown: 'An error occured while logging in'
		}
	},

	NB: {
		title: 'Logg inn',

		usernameInput: {
			label: 'Brukernavn eller e-post',
			placeholder: 'Skriv inn brukernavn eller e-post',
			validation: {
				username: 'Brukernavn eller e-post er påkrevd'
			}
		},
		passwordInput: {
			label: 'Passord',
			placeholder: 'Skriv inn passord',
			validation: {
				password: 'Passord er påkrevd'
			}
		},

		loginButtonText: 'Logg inn',

		forgotPasswordLinkText: 'Glemt passord?',

		errors: {
			notAuthorized: 'Ugyldig brukernavn eller passord',
			unknown: 'En feil oppsto under innlogging'
		}
	}
} as const;
type Dictionary = (typeof DICTIONARY)[keyof typeof DICTIONARY];

export const isNotAuthorizedError = (error: unknown) => {
	if (error instanceof Error) {
		return error.name === 'NotAuthorizedException';
	}
	return false;
};
