import { AuthUser, fetchAuthSession, getCurrentUser, signOut } from '@aws-amplify/auth';
import { isUserUnAuthenticatedException } from 'features/auth/amplify/amplify-errors';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';

export const logout = async () => {
	try {
		const currentUser = await getCurrentUser();
		const logoutUrl = await getLogoutUrl(currentUser);

		await signOut({ global: true });

		// Use replace instead of href to prevent back button from returning to logged-in state
		window.location.replace(logoutUrl);
	} catch (error) {
		if (isUserUnAuthenticatedException(error)) {
			return;
		}

		throw error;
	}
};

async function getLogoutUrl(user: AuthUser) {
	if (isSSOLogin(user)) {
		const ssoLogoutUrl = await getSSOLogoutUrl();

		if (ssoLogoutUrl) {
			return ssoLogoutUrl;
		}
	}

	return ROUTE_MAP.auth.logout.createPath();
}

function isSSOLogin(currentUser: AuthUser) {
	return currentUser?.signInDetails?.authFlowType === undefined;
}

const getSSOLogoutUrl = async (): Promise<string | null> => {
	const session = await fetchAuthSession();
	const identities = session.tokens?.idToken?.payload?.identities;

	if (!(identities instanceof Array)) {
		return null;
	}

	const provider = identities[0];
	const ssoLogoutUrl = extractFederatedLogoutUrl(provider.providerName);
	if (!ssoLogoutUrl) {
		console.error('No SSO logout URL found for provider', provider.providerName);
		return null;
	}

	const redirectUrl = `https://${SSO_URL_BASE}/logout?client_id=${CLIENT_ID}&logout_uri=${encodeURIComponent(
		ssoLogoutUrl
	)}`;

	return redirectUrl;
};

const extractFederatedLogoutUrl = (providerName: string) => {
	const federatedLogoutUrls = JSON.parse(process.env.REACT_APP_FEDERATED_LOGOUT_URLS ?? '{}');
	const ssoLogoutUrl = federatedLogoutUrls[providerName.toLowerCase()];

	if (providerName === 'LedidiSSOtest') {
		// Support our different environments (local, systest, feature branch)
		return `${ssoLogoutUrl}&logout_uri=${window.location.origin + '/login'}`;
	}

	return federatedLogoutUrls[providerName.toLowerCase()];
};

const SSO_URL_BASE = process.env.REACT_APP_SSO_URL_BASE!;
const CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!;
